import './BarCharts.css';

function BarCharts() {
    return ( <
        div className = "logo-holder ease-in-ui" >
        <
        div className = "bg" > < /div><div className="bar"></div >
        <
        div className = "bar fill1" > < /div><div className="bar fill2"></div >
        <
        div className = "bar fill3" > < /div><div className="bar fill4"></div >
        <
        div className = "bar fill1" > < /div><div className="bar fill5"></div >
        <
        div className = "bar fill6" > < /div><div className="bar"></div >
        <
        div className = "bar fill3" > < /div><div className="bar fill4"></div >
        <
        div className = "bg" > < /div><div className="bar"></div >
        <
        /div>  


    );
}

export default BarCharts